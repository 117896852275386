<template>
  <div class="add-fund">
    <backtitle lable="资金方管理" />
    <div class="layout-box">
      <!-- 头部表单 -->
      <base-form
        :componentList="form"
        :showBtns="false"
        :formAttrs="{
          model: queryParas,
          labelWidth: '120px',
          'label-position': 'right',
        }"
        class="form-style top"
        ref="fromdata"
      />
      <!-- 新增 -->
      <div class="product">
        <title-name-slot title="产品信息" />
        <base-button
          type="primary"
          label="添加产品信息"
          @click.prevent="addProductInfo"
          icon="el-icon-plus"
          v-if="!$route.query.keyId"
        />
      </div>
      <!-- 详情信息列表 -->
      <div class="product-list">
        <div v-for="(item, index) of formDataList" :key="index" class="form">
          <div
            class="del"
            @click="delProductInfo(index)"
            v-if="!$route.query.keyId"
          >
            <i class="el-icon-delete" />
          </div>
          <base-form
            :componentList="dialogconfig(index)"
            :showBtns="false"
            :formAttrs="{
              model: item,
              labelWidth: '150px',
            }"
            :keyIndex="index"
            class="formStyle"
            :class="{ border: index !== formDataList.length - 1 }"
            ref="formDataList"
          />
        </div>
      </div>
      <!-- 底部按钮 -->
      <div class="bottom-submit">
        <base-button label="提交" @click="commit" />
        <base-button
          label="关闭"
          @click="
            () => {
              this.$router.back();
            }
          "
          type="default"
        />
      </div>
    </div>
  </div>
</template>
<script>
import backtitle from '@/pages/business/components/backtitle.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import titleNameSlot from '@/pages/dashboard/components/title-name-slot.vue'
import BaseForm from '@/components/common/base-form/base-form'
import { formConfig, dialogConfig } from './utils/add-find-config'
import { fundManagementApi } from '@/api/fundManagementApi'
import { getDictLists } from '@/filters/fromDict'
import { fundsproviderApi } from '@/api/productApi'
export default {
  name: 'addFund',
  components: { BaseForm, titleNameSlot, BaseButton, backtitle },
  data () {
    return {
      formDataList: [],
      Visible: false,
      typelist: [],
      queryParas: {}
    }
  },
  computed: {
    form () {
      return formConfig(this)
    },
    dialogconfig () {
      return function (index) {
        return dialogConfig(this, index)
      }
    },
    api () {
      return fundManagementApi
    },
    capitalSideType () {
      return getDictLists('CAPITAL_TYPE')
    },
    borrowerType () {
      return getDictLists('BORROWER_TYPE')
    },
    replyStatus () {
      return getDictLists('CAPITAL_APPLY_STATUS')
    },
    sealOptions () {
      return (getDictLists('SUPPLIERS_SEAL_TYPE')).filter(item => { // 过滤2.未确定类型
        if (item.dictId !== '2') {
          return item
        }
      })
    }
  },
  mounted () {
    this.getUpdataList()
  },
  methods: {
    // 获取资金方类型
    getType () {
      this.api
        .getFundsproviderType()
        .then((res) => {
          if (res) {
            this.typelist = res.data.map((item, index) => {
              item = { name: item, id: index }
              return item
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 添加产品信息
    addProductInfo () {
      if (this.formDataList.length === 0 && !this.$refs.formDataList) {
        this.formDataList.push({})
      }
      if (this.$refs.formDataList) {
        let pass = true
        this.$refs.formDataList.forEach((item) => {
          item.validate((valid, index) => {
            if (!valid) {
              pass = false
            }
          })
        })
        if (pass) {
          this.formDataList.push({})
        } else {
          this.$message('请先完成当前添加')
        }
      }
    },
    // 删除产品信息
    delProductInfo (index) {
      console.log(this.formDataList[index])
      const list = JSON.parse(JSON.stringify(this.formDataList[index]))
      let pass = true
      if (list) {
        for (const key in list) {
          if (list[key]) {
            pass = false
          }
        }
      }
      if (!pass) {
        this.$stateConfirm({
          title: '提示',
          message: '已有内容确认删除吗？',
          show: true,
          type: 'warning'
        })
          .then(() => {
            this.formDataList.splice(index, 1)
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '取消删除'
            })
          })
      } else {
        this.formDataList.splice(index, 1)
      }
    },
    // 上传文件信息
    uploadFile (data) {
      if (data) {
        this.queryParas.imageUrl = data.keyId
      }
    },
    // 新增or修改 提交
    commit () {
      let status = true
      this.$refs.fromdata.validate((valid) => {
        if (!valid) {
          status = false
        }
      })
      // 校验
      if (this.$refs.formDataList) {
        this.$refs.formDataList.forEach((item) => {
          item.validate((valid) => {
            if (!valid) {
              status = false
            }
          })
        })
      }
      if (!status) {
        return false
      } else {
        if (this.formDataList && this.formDataList.length > 0) {
          this.formDataList.forEach((item) => {
            console.log(item.effectiveStartTime, item.applicationdate)
            item.effectiveStartTime = item.applicationdate && item.applicationdate[0] ? item.applicationdate[0] : null
            item.effectiveEndTime = item.applicationdate && item.applicationdate[1] ? item.applicationdate[1] : null
          })
          if (this.$route.query.keyId) {
            // 修改
            const presentedData = {
              ...this.queryParas,
              ...this.formDataList[0]
            }
            this.api.updataProductInfo(presentedData).then((res) => {
              if (res.data) {
                this.success('修改成功')
                this.$router.back()
              } else {
                this.error('修改失败')
              }
            })
          } else {
            // 新增
            const presentedData = [this.queryParas, ...this.formDataList]
            this.api.newAddProduct(presentedData).then((res) => {
              if (res.data) {
                this.success('新增成功')
                this.$router.back()
              } else {
                this.error('新增失败')
              }
            })
          }
        } else {
          this.warning('请添加产品信息')
        }
      }
    },
    // 时间处理
    getDate (val) {
      if (val) {
        return (
          new Date(val).getFullYear() +
          '-' +
          (new Date(val).getMonth() + 1) +
          '-' +
          new Date(val).getDate()
        )
      } else {
        return '--'
      }
    },
    // 要修改数据
    getUpdataList () {
      const keyId = this.$route.query.keyId
      if (keyId) {
        fundsproviderApi.getFundsproviderList({ keyId: keyId }).then((res) => {
          const { capitalSideName, capitalSideType, imageUrl, isAuthorized, isCredit, ...rest } = {
            ...res.data
          }
          this.queryParas = {
            capitalSideName: capitalSideName,
            capitalSideType: capitalSideType,
            imageUrl: imageUrl,
            isAuthorized: isAuthorized,
            isCredit: isCredit
          }
          const [obj] = this.borrowerType.filter(
            (item) => item.dictId === res.data.borrowerType
          )
          rest.borrowerTypeName = obj ? obj.dictName : ''
          this.formDataList = [rest]
          // 数据库，如果传null 会自动返回这个时间戳
          if (rest.effectiveEndTime === -2209017600000) {
            this.$set(this.formDataList[0], 'applicationdate', ['', ''])
          } else {
            this.$set(this.formDataList[0], 'applicationdate', [
              this.getDate(rest.effectiveStartTime),
              this.getDate(rest.effectiveEndTime)
            ])
          }
        })
      }
    },
    // 资金方类型
    changeCapitalSideType (data) {
      const [obj] = this.capitalSideType.filter((item) => item.dictId === data)
      this.queryParas.capitalSideTypeName = obj ? obj.dictName : ''
    },
    // 申报状态
    changeReplyStatus (data) {
      const [obj] = this.replyStatus.filter((item) => item.dictId === data)
      if (this.formDataList) {
        this.formDataList.forEach((item) => {
          item.replyStatusName = obj ? obj.dictName : ''
        })
      }
    },
    // 借款主体类型：
    changeBorrowerType (data) {
      const [obj] = this.borrowerType.filter((item) => item.dictId === data)
      if (this.formDataList) {
        this.formDataList.forEach((item) => {
          item.borrowerTypeName = obj ? obj.dictName : ''
        })
      }
    },
    // 盖章类型
    changeSealMethod (data) {
      const [obj] = this.sealOptions.filter((item) => item.dictId === data)
      if (this.formDataList) {
        this.formDataList.forEach((item) => {
          item.sealMethodName = obj ? obj.dictName : ''
        })
      }
    }
  }
}
</script>
<style lang="scss" src='./index.scss' scoped/>
