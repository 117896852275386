import BaseSelect from '@/components/common/base-select/base-select'
import radio from '../components/radio.vue'
import amountInput from '@/components/input/amount-input/amount-input'
import upDataBankIcon from '../components/upload-bank-Icon.vue'
// import { getDictLists } from '@/filters/fromDict'
// 头部类型form
export const formConfig = (content) => {
  const authorizedOptions = [{ label: '是', value: '1' }, { label: '否', value: '0' }]
  const cols = { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 }
  return [
    {
      label: '资金方名称：',
      prop: 'capitalSideName',
      rules: [{ required: true, message: '请输入资金方名称', trigger: 'blur' }],
      cols,
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '资金方类型：',
      prop: 'capitalSideType',
      tag: BaseSelect,
      rules: [{ required: true, message: '请选择资金方类型', trigger: 'change' }],
      cols,
      attrs: {
        placeholder: '请选择',
        options: content.capitalSideType,
        selectedField: ['dictId', 'dictName']
      },
      on: {
        change: (data) => {
          content.changeCapitalSideType(data)
        }
      }
    },
    {
      label: '资金方logo：',
      prop: 'imageUrl',
      tag: upDataBankIcon,
      cols,
      attrs: {
        imageUrlKeyId: content.queryParas.imageUrl
      },
      on: {
        uploadFile: (data) => {
          content.uploadFile(data)
        }
      }
    },
    {
      label: '是否需要授权：',
      prop: 'isAuthorized',
      tag: radio,
      cols,
      attrs: {
        placeholder: '请选择',
        options: authorizedOptions
      }
    },
    {
      label: '是否需要授信：',
      prop: 'isCredit',
      tag: radio,
      cols,
      attrs: {
        placeholder: '请选择',
        options: authorizedOptions
      }
    }
  ]
}
// 新增内容form
export const dialogConfig = (content, index) => {
  const circleQuotaOptions = [{ label: '是', value: '1' }, { label: '否', value: '0' }]
  const borrowerRequirementOptions = [{ label: '有', value: '0' }, { label: '无', value: '1' }]
  const startDatePicker = {
    disabledDate (time) {
      // 暂时取消
      // return time.getTime() <= Date.now() - (24 * 60 * 60 * 1000)
      return time.getTime() <= new Date('1989-12-30')
    }
  }
  return [
    {
      label: '资方产品名称',
      prop: 'productName',
      rules: [{ required: true, message: '请输入资方产品名称', trigger: 'blur' }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '融资利率(%)',
      prop: 'productRate',
      rules: [{ required: true, message: '请输入融资利率', trigger: 'blur' }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        type: 'BL',
        placeholder: '请输入'
      },
      tag: amountInput
    },
    {
      label: '融资额度（元）：',
      prop: 'replyQuota',
      tag: amountInput,
      rules: [{ required: true, message: '请输入融资额度', trigger: 'blur' }],
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        type: 'Y',
        placeholder: '请输入'
      }
    },
    {
      label: '借款主体类型：',
      prop: 'borrowerType',
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      tag: BaseSelect,
      rules: [{ required: true, message: '请选择借款主体类型', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.borrowerType,
        selectedField: ['dictId', 'dictName']
      },
      on: {
        change: (data) => {
          content.changeBorrowerType(data)
        }
      }
    },
    {
      label: '盖章方式：',
      prop: 'sealMethod',
      tag: BaseSelect,
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: true, message: '请选择盖章方式', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.sealOptions,
        selectedField: ['dictId', 'dictName']
      },
      on: {
        change: (data) => {
          content.changeSealMethod(data)
        }
      }
    },
    {
      label: '是否为循环额度：',
      prop: 'circleQuotaFlag',
      tag: radio,
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      attrs: {
        placeholder: '请选择',
        options: circleQuotaOptions
      },
      rules: [{ required: true, message: '请选择是否循环额度', trigger: 'change' }]
    },
    {
      label: '额度有效期：',
      prop: 'applicationdate',
      tag: 'el-date-picker',
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: false, message: '请选择额度有效期', trigger: 'change' }],
      attrs: {
        editable: false,
        isRange: true,
        type: 'daterange',
        rangeSeparator: '至',
        startPlaceholder: '',
        endPlaceholder: '',
        placeholder: '选择时间范围',
        'value-format': 'yyyy-MM-dd',
        pickerOptions: startDatePicker
      },
      on: {
        change: (data) => {
          if (data && data.length > 0) {
            if (data[0] === data[1]) {
              content.$set(content.formDataList[index], 'applicationdate', [])
              content.warning('额度有效期不能为同一天')
            }
          }
        }
      }
    },
    {
      label: '申报状态：',
      prop: 'replyStatus',
      tag: BaseSelect,
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: true, message: '请选择申报状态', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.replyStatus,
        selectedField: ['dictId', 'dictName']
      },
      on: {
        change: (data) => {
          content.changeReplyStatus(data)
        }
      }
    },
    {
      label: '共借人要求：',
      prop: 'borrowerRequirementFlag',
      tag: radio,
      cols: { xs: 24, sm: 12, md: 8, lg: 5, xl: 6 },
      rules: [{ required: true, message: '请选择共借人要求', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: borrowerRequirementOptions
      }
    },
    {
      label: '资金方案担保措施：',
      prop: 'guaranteeMeasures',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      span: 24,
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        rows: 2
      }
    },
    {
      label: '底层资产要求：',
      prop: 'assetRequirement',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      span: 24,
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        rows: 2
      }
    },
    {
      label: '还款方式:',
      prop: 'repayStyle',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      span: 24,
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        rows: 2
      }
    }
  ]
}
