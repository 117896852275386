var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-fund"},[_c('backtitle',{attrs:{"lable":"资金方管理"}}),_c('div',{staticClass:"layout-box"},[_c('base-form',{ref:"fromdata",staticClass:"form-style top",attrs:{"componentList":_vm.form,"showBtns":false,"formAttrs":{
        model: _vm.queryParas,
        labelWidth: '120px',
        'label-position': 'right',
      }}}),_c('div',{staticClass:"product"},[_c('title-name-slot',{attrs:{"title":"产品信息"}}),(!_vm.$route.query.keyId)?_c('base-button',{attrs:{"type":"primary","label":"添加产品信息","icon":"el-icon-plus"},on:{"click":function($event){$event.preventDefault();return _vm.addProductInfo.apply(null, arguments)}}}):_vm._e()],1),_c('div',{staticClass:"product-list"},_vm._l((_vm.formDataList),function(item,index){return _c('div',{key:index,staticClass:"form"},[(!_vm.$route.query.keyId)?_c('div',{staticClass:"del",on:{"click":function($event){return _vm.delProductInfo(index)}}},[_c('i',{staticClass:"el-icon-delete"})]):_vm._e(),_c('base-form',{ref:"formDataList",refInFor:true,staticClass:"formStyle",class:{ border: index !== _vm.formDataList.length - 1 },attrs:{"componentList":_vm.dialogconfig(index),"showBtns":false,"formAttrs":{
            model: item,
            labelWidth: '150px',
          },"keyIndex":index}})],1)}),0),_c('div',{staticClass:"bottom-submit"},[_c('base-button',{attrs:{"label":"提交"},on:{"click":_vm.commit}}),_c('base-button',{attrs:{"label":"关闭","type":"default"},on:{"click":function () {
            this$1.$router.back();
          }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }