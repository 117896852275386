<template>
  <div class="upload-bank-Icon">
    <base-button
      :http-request="UploadFile"
      :btnType="'upload'"
      label="上传logo"
      class="el_upload"
      icon="iconfont iconshangchuan"
      v-if="showBtn"
      accept=".png,.jpg,.gif,.bmp,.jpeg,.webp"
    />
    <div v-else class="imgbox">
      <img :src="imageUrl" class="show-img" />
      <i class="iconfont iconshanchu2" @click="delFile" />
    </div>
  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { fileAPi } from '@/api/fileApi'
export default {
  components: { BaseButton },
  props: {
    imageUrlKeyId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      imageUrl: '',
      showBtn: true
    }
  },
  methods: {
    // logo上传
    UploadFile (param) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      fileAPi.file(formData).then((res) => {
        if (res.success) {
          this.imageUrl = URL.createObjectURL(param.file)
          this.showBtn = false
          this.$emit('uploadFile', res.data)
          this.success('文件上传成功')
        }
      })
    },
    // 删除logo
    delFile () {
      this.showBtn = true
    }
  },
  computed: {
    keyId () {
      return this.imageUrlKeyId
    }
  },
  watch: {
    keyId: {
      handler (newkeyId) {
        if (newkeyId) {
          this.showBtn = false
          fileAPi.downloadKeyId(newkeyId).then((res) => {
            this.imageUrl = res.data.uri
          })
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-bank-Icon {
  display: flex;
  .show-img {
    width: 28px;
    height: 28px;
  }
}
.imgbox {
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iconshanchu2 {
  font-size: 18px;
  color: #f34145;
  display: inline-block;
  cursor: pointer;
}
</style>
