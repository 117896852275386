import Rest from '@/utils/http/Rest'
import http from '@/utils/http'

export default class fundManagement extends Rest {
  /**
   * 删除资金方产品信息
   * @returns {AxiosPromise}
   */
  DelectProductInfo (params) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/`,
      params
    })
  }

  /**
   *修改资金方产品信息
   * @param params
   */
  updataProductInfo (data) {
    return http({
      showLoading: true,
      method: 'put',
      url: `/api/${this.subSys}/${this.module}/`,
      data
    })
  }

  /**
   *新增资金方产品信息
   * @param params
   */
  newAddProduct (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/`,
      data
    })
  }

  /** 获取资方类型下拉菜单
   *
   * @returns {AxiosPromise}
   */
  getFundsproviderType () {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/type`
    })
  }

  /** 获取资方名称下拉菜单
   *
   * @returns {AxiosPromise}
   */
  getFundsprovidertName () {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/name`
    })
  }

  /**
   *获取资方产品下拉菜单
   * @returns {AxiosPromise}
   */
  getProductList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/product`,
      params
    })
  }

  getCapitalList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/list`,
      params
    })
  }

  /**
   *获取资方名称下拉菜单
   * @returns {AxiosPromise}
   */
  getFundsproviderInfo (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/info`,
      params
    })
  }
}
